import { NextSeo } from 'next-seo';

import placeholder from 'lib/placeholder';

const BlogHead = () => (
  <NextSeo
    description={placeholder('seo.blog.description')}
    openGraph={{
      title: placeholder('seo.blog.title'),
      description: placeholder('seo.blog.description'),
    }}
    title={placeholder('seo.blog.title')}
  />
);

export default BlogHead;
