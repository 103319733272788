import BlogPage from 'components/pages/Blog';

import { toSidebarFeaturedOffers } from 'lib/offer';
import { resolvify } from 'lib/promises';

import { getTopbarAd } from 'services/ads';
import {
  getArticleCategoriesList,
  getArticlesList,
  getLastArticleReviews,
} from 'services/article';
import { getCategoriesList } from 'services/category';
import { getLandingsList } from 'services/landing';
import { getFeaturedOffersList } from 'services/offer';
import { getSuggestedSearchKeywordsList } from 'services/search';
import { getStoresList } from 'services/store';

import APP from 'constants/app';
import BLOG from 'constants/blog';
import LINKS from 'constants/links';

const Blog = (props) => <BlogPage {...props} />;

export const getServerSideProps = async ({ query }) => {
  try {
    const [
      articles,
      blogCategories,
      reviews,
      { recommendedOffers },
      featuredOffers,
      categories,
      stores,
      featuredLandings,
      highlightedLandings,
      topbarAd,
    ] = await resolvify([
      getArticlesList({
        limit: BLOG.ARTICLES.SERVER_SIDE_LIMIT,
        ...query,
      }),
      getArticleCategoriesList(),
      { fn: getLastArticleReviews(), fallback: getLastArticleReviews.fallback },
      {
        fn: getSuggestedSearchKeywordsList({ withOffers: true }),
        fallback: { recommendedOffers: [] },
      },
      { fn: getFeaturedOffersList(), fallback: getFeaturedOffersList.fallback },
      {
        fn: getCategoriesList({ selectedCategories: true }),
        fallback: getCategoriesList.fallback,
      },
      {
        fn: getStoresList({ from: 'topbar' }),
        fallback: getStoresList.fallback,
      },
      {
        fn: getLandingsList({ featured: true }),
        fallback: getLandingsList.fallback,
      },
      {
        fn: getLandingsList({ highlighted: true }),
        fallback: getLandingsList.fallback,
      },
      { fn: getTopbarAd(), fallback: getTopbarAd.fallback },
    ]);

    const sidebarFeaturedOffers = toSidebarFeaturedOffers(
      recommendedOffers,
      featuredOffers
    );

    return {
      props: {
        reviews,
        sidebarFeaturedOffers,
        blogCategories,
        mainArticles: articles.articles.slice(0, BLOG.ARTICLES.MAIN_LIMIT),
        serverArticlesPage: {
          ...articles,
          articles: articles.articles.slice(BLOG.ARTICLES.MAIN_LIMIT),
        },
        [APP.CTX_OPTIONAL_PROPS]: {
          categories,
          featuredLandings,
          highlightedLandings,
          stores,
          topbarAd,
        },
      },
    };
  } catch {
    return { redirect: { destination: LINKS[500], permanent: false } };
  }
};

export default Blog;
